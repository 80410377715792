/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, SeparateLine, SeparateLineWrap, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"akce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"bd9iov63ube"} parallax={false}>
        </Column>


        <SeparateLine className="pb--10 pt--10" name={"rm0ora4cl2a"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pt--60" style={{"paddingBottom":236,"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"h5abkta3exo"} parallax={false} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3 --full" style={{"maxWidth":1489}} columns={"3"} fullscreen={true}>
            
            <ColumnWrapper className="--shape2">
              
              <Title className="title-box title-box--left" content={"<span style=\"font-weight: bold; color: rgb(37, 8, 244);\">Duben&nbsp;</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">sportovní odpoledne<br></span>3.4. dětský domov Prostějov</span>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">zahájení spolupráce s novým DD<br></span>6.-7.4. dětský domov Rovečné&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">sportovní odpoledne\n<br></span>10.4. dětský domov Prostějov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">sportovní odpoledne\n<br></span>17.4. dětský domov Prostějov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">talentová soutěž pro dětské domovy<br></span>16.-17. 4. dětský domov Tachov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">víkendovka pro děti&nbsp;<br></span>20.-21.4. dětský domov Ml. Boleslav&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">sportovní odpoledne<br></span>24.4. dětský domov Prostějov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">víkendovka pro děti&nbsp;<br></span>27.-28.4. dětský domov Sedloňov</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape2">
              
              <Title className="title-box title-box--left" content={"<span style=\"font-weight: bold; color: rgb(37, 8, 244);\">Květen&nbsp;</span>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva dětí<br></span>2.5. dětský domov Střekov</span>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva zařízení&nbsp;<br></span>3.5. výchovný ústav Boletice&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">zahájení spolupráce&nbsp;<br></span>4.5. dětský domov se školou Místo&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">sportovní odpoledne\n<br></span>16.5. dětský domov Prostějov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">víkendovka pro děti&nbsp;<br></span>18.-19.5. dětský domov Rovečné&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">příprava letního pobytu<br></span>22.5. dětský domov Litovel</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">příprava letního pobytu<br></span>23.5. dětský domov Vrbno pod Pradědem</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">zahradní slavnost<br></span>31.5. dětský domov Krásná Lípa&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shape2">
              
              <Title className="title-box title-box--left" content={"<span style=\"color: rgb(37, 8, 244); font-weight: 700;\">Červen&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">víkendovka pro děti&nbsp;<br></span>7.-9.6. dětský domov Horní Slavkov</span>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">jarní pobyt v Krkonoších<br></span>10.-14.6. dětský domov Litovel&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva dětí&nbsp;<br></span>15.6. dětský domov Nová Ves u Chotěboře&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva zařízení / open day&nbsp;<br></span>20.6. výchovný ústav Pšov&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva zařízení / open day<br></span>21.6. výchovný ústav Buškovice&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva dětí&nbsp;<br></span>27.6. dětský domov Prostějov</span><br>"}>
              </Text>

              <Text className="text-box fs--16" style={{"maxWidth":467}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: 700;\">návštěva dětí&nbsp;<br></span>28.6. dětský diagnostický ústav Hradec Králové</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xqiycxwfbk"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"font-weight: bold;\">Čtvrtletník organizace za duben, květen, červen 2024 k zobrazení níže.&nbsp;</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5" content={"klikněte sem"} url={"https://www.canva.com/design/DAGFHCLa2B0/Ob64zIyMnTIeG0jHzux9SA/view?utm_content=DAGFHCLa2B0&utm_campaign=designshare&utm_medium=link&utm_source=editor"} href={"https://www.canva.com/design/DAGFHCLa2B0/Ob64zIyMnTIeG0jHzux9SA/view?utm_content=DAGFHCLa2B0&utm_campaign=designshare&utm_medium=link&utm_source=editor"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"j2faxdsk8u"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}